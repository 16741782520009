import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MyNavbar from "./components/Header";
import Home from "./pages/Home";
import Payment from "./pages/Payment";
import GreatCategory from "./pages/User/GreatCategory";
import ListeningCategory from "./pages/User/ListeningCategory";
import UpdateCategory from "./pages/User/UpdateCategory";
import Course from "./pages/User/Course";
import CourseOfCategory from "./pages/User/CourseOfCategory";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Admin from "./pages/Admin/Admin";
import { isAdmin, isLogin } from "./utils";
import "./App.css";
import { FaTimes } from "react-icons/fa";
import { useGlobalState } from "./context/GlobalContext";
import { DOMAIN } from "./constants";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  return isLogin() ? element : <Navigate to="/login" />;
};

const closeIconStyles = {
  position: "absolute",
  top: "-10px",
  right: "-5px",
  width: "30px",
  height: "30px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  cursor: "pointer",
  color: "#333",
  border: "1px solid #ccc",
  zIndex: "1050",
  transition: "background-color 0.3s ease, color 0.3s ease",
};

const PublicRouter = ({ element }) => {
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const { state } = useGlobalState();
  const popupData = state.popupData.find((x) => x.type === "POPUP_REGISTER");

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const isLoggedIn = isLogin();
    const popupKey = "hasSeenPopup";
    const popupExpirationKey = "popupExpirationTime";
    const expirationDuration = 24 * 60 * 60 * 1000;

    const currentTime = new Date().getTime();
    const expirationTime = localStorage.getItem(popupExpirationKey);

    if (expirationTime && currentTime > expirationTime) {
      localStorage.removeItem(popupKey);
      localStorage.removeItem(popupExpirationKey);
    }

    const hasSeenPopup = localStorage.getItem("hasSeenPopup");

    if (!isLoggedIn && !hasSeenPopup) {
      setShowPopup(true);
      localStorage.setItem(popupKey, "true");

      localStorage.setItem(
        popupExpirationKey,
        currentTime + expirationDuration
      );
    }
  }, []);

  return (
    <div className="root-content">
      <div className="root-content-header">
        <MyNavbar />
      </div>
      <div className="root-content-body">{element}</div>
      {showPopup && popupData && (
        <Modal show={showPopup} onHide={handleClosePopup}>
          <Modal.Body
            style={{
              position: "relative",
              padding: 0,
            }}
          >
            <span style={closeIconStyles} onClick={handleClosePopup}>
              <FaTimes />
            </span>
            <img
              style={{ height: "auto", objectFit: "cover" }}
              className="d-block w-100"
              src={`${DOMAIN}${popupData.image}`}
              alt=""
            />
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#58d062" }}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#fff",
                  width: "80%",
                  color: "#000",
                  padding: "10px",
                  borderRadius: "10px",
                  textTransform: "uppercase",
                  fontWeight: "600",
                  borderColor: "#ddd",
                }}
                onClick={() => {
                  setShowPopup(false);
                  navigate("/sign-up");
                }}
              >
                Đăng ký ngay
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

const App = () => {
  return (
    <div className={isAdmin() ? "admin-content" : "root-content"}>
      <Router>
        <div style={{ width: "100%", flex: 1 }}>
          <Routes>
            <Route
              path="/"
              exact
              element={<PublicRouter element={<Home />} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route
              path="/great-category"
              element={<PublicRouter element={<GreatCategory />} />}
            />
            <Route
              path="/kaiwa-category"
              element={
                <PublicRouter element={<ListeningCategory type={"kaiwa"} />} />
              }
            />
            <Route
              path="/jlpt-category"
              element={
                <PublicRouter element={<ListeningCategory type={"jlpt"} />} />
              }
            />
            <Route
              path="/listening-category"
              element={
                <PublicRouter
                  element={<ListeningCategory type={"listening"} />}
                />
              }
            />
            <Route
              path="/update-category"
              element={<PublicRouter element={<UpdateCategory />} />}
            />
            <Route
              path="/payment"
              element={<PublicRouter element={<Payment />} />}
            />
            <Route
              path="/category/:id"
              element={<PublicRouter element={<Course />} />}
            />
            <Route
              path="/sub-category/:id"
              element={<PublicRouter element={<CourseOfCategory />} />}
            />
            <Route
              path="/admin/*"
              element={<ProtectedRoute element={<Admin />} />}
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;

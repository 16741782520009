import { jwtDecode } from "jwt-decode";
import { DOMAIN } from "../constants";

export const isAdmin = () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return false;
    const decoded = jwtDecode(token);
    return decoded?.roles.includes("ROLE_ADMIN");
  } catch (error) {
    return false;
  }
};

export const isLogin = () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return false;
    const decoded = jwtDecode(token);
    if (!decoded) return false;

    return Date.now() < decoded.exp * 1000;
  } catch (error) {
    return false;
  }
};

export const isLoginAndPayment = () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return false;
    const decoded = jwtDecode(token);
    if (!decoded) return false;
    const exp = Date.now() < decoded.exp * 1000;
    if (!exp) return false;
    if (decoded?.roles.includes("ROLE_ADMIN")) return true;
    if (decoded.payment === 0) return false;
    return true;
  } catch (error) {
    return false;
  }
};

export const getImage = (image) => {
  if (!image) return image;
  if (image.includes("/uploads")) {
    return `${DOMAIN}${image}`;
  }
  return image;
};

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { SiZalo } from "react-icons/si";
import { fetchList } from "../services";
import { TailSpin } from "react-loader-spinner";
import { useWindowWidth } from "../context/WindowWidthContext";
import { useGlobalState } from "../context/GlobalContext";
import { FaCircle } from "react-icons/fa";
import { BsTriangleFill } from "react-icons/bs";
import image1 from "../assets/images/bank.png";
import "./payment.css";

const styles = {
  contentMasterCategory: (isMobile) => ({
    backgroundColor: "#fff",
    borderRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
  }),
  contentMasterCategoryLoading: (isMobile) => ({
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  contentContact: (isMobile) => ({
    margin: !isMobile ? "5px 20px 0 20px" : "5px 0px 0 0px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    padding: "10px 30px",
    display: "flex",
    alignItems: "center",
  }),
  contentDescription: {
    color: "red",
    display: "flex",
    alignItems: "center",
  },
  contentDescription1: {
    display: "flex",
  },
  informationBank: {
    display: "flex",
    backgroundImage: 'url("/public/images/background.jpg")',
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
};

const Payment = () => {
  const { isMobile } = useWindowWidth();
  const { state } = useGlobalState();
  const support = state.supportData;
  const [allMasterCategories, setAllMasterCategories] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const rootDatas = await fetchList(
        "/public/root-categories-master-update"
      );
      if (rootDatas && rootDatas.length > 0) {
        setAllMasterCategories(rootDatas[0]);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Container
      className="main-content"
      style={{
        paddingBottom: "10px",
        flexGrow: 1,
        minHeight: "calc(100vh - 60px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <div style={styles.contentMasterCategoryLoading(isMobile)}>
          <TailSpin color="green" radius={"8px"} />
        </div>
      ) : (
        <div style={styles.contentMasterCategory(isMobile)}>
          <div style={{ padding: "20px 0 30px 0" }}>
            <div
              style={{
                padding: "20px",
                marginBottom: "20px",
                borderRadius: "10px",
                backgroundColor: "#58d062",
                boxShadow:
                  " 2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                className="d-flex"
                style={{
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%", textAlign: "center" }}>
                  <div
                    style={{
                      fontSize: "26px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Hướng Dẫn Thanh Toán Khóa Học Tại
                  </div>
                  <div style={{ fontSize: "18px", fontWeight: "600" }}>
                    Khotiengnhat.com
                  </div>
                </div>
              </div>
            </div>
            <div style={{ margin: "15px 0" }}>
              <div style={styles.contentDescription}>
                <div style={{ marginTop: "-3px" }}>
                  <FaCircle style={{ color: "#d73061" }} />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  Thông Báo Quan Trọng:
                </div>
              </div>

              <div style={styles.contentDescription1}>
                <div style={{ marginTop: "-3px", position: "relative" }}>
                  <BsTriangleFill style={{ color: "#cd9d56" }} />
                  <span
                    style={{
                      position: "absolute",
                      top: "4px",
                      fontSize: "13px",
                      left: "7px",
                    }}
                  >
                    !
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  Từ ngày 08/11/2024, Hội phí trọn đời tại website trở về giá
                  gốc <strong>1.500.000đ</strong>. Mức phí này sẽ áp dụng cho
                  tất cả các các thành viên mới.
                </div>
              </div>

              <div style={styles.contentDescription1}>
                <div style={{ marginTop: "-3px", position: "relative" }}>
                  <BsTriangleFill style={{ color: "#cd9d56" }} />
                  <span
                    style={{
                      position: "absolute",
                      top: "4px",
                      fontSize: "13px",
                      left: "7px",
                    }}
                  >
                    !
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  Trước ngày trên, vẫn áp dụng mức <strong>599.000đ</strong>{" "}
                  trên 1 khóa học trọn đời.
                </div>
              </div>
            </div>

            <div style={{ margin: "15px 0" }} className="wrap-information-bank">
              <div className="information-bank">
                <div
                  style={{
                    fontSize: "40px",
                    color: "green",
                    textDecoration: "underline",
                    fontWeight: "600",
                  }}
                >
                  01
                </div>
                <div
                  style={{
                    flex: "1",
                    paddingLeft: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      style={{
                        color: "red",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Chuyển khoản tới VietcomBank
                    </div>
                    <div
                      style={{
                        color: "red",
                        fontSize: "20px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      001110010010011010 - Mai Ngọc Phương
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: "green",
                        }}
                      ></div>
                      <div style={{ marginLeft: "10px" }}>
                        <label style={{ width: "90px" }}>Nội dung</label>: Họ và
                        Tên - Số điện thoại
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: "green",
                        }}
                      ></div>
                      <div style={{ marginLeft: "10px" }}>
                        <label style={{ width: "90px" }}>Số tiền</label>
                        {": "}
                        599,000đ.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: "green",
                        }}
                      ></div>
                      <div style={{ marginLeft: "10px" }}>
                        Học full các khóa học tại website.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: !isMobile ? "200px" : "150px",
                    padding: "5px",
                    border: "1px solid green",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    src={image1}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div style={{ margin: "15px 0", display: "flex" }}>
              <div style={{ width: "80%", color: "#000", fontWeight: "600" }}>
                Lưu ý: Sau khi chuyển khoản, vui lòng chụp bill chuyển khoản,
                gửi tới đường link bên cạnh để admin phê duyệt.
              </div>
              <div style={{ width: "20%" }}>
                <Button
                  variant="success"
                  className="login-button"
                  style={{ borderRadius: "20px" }}
                >
                  Nhắn Tin Admin
                </Button>
                <Button
                  variant="success"
                  className="login-button"
                  style={{ marginTop: "5px", borderRadius: "20px" }}
                >
                  Zalo Hỗ Trợ
                </Button>
              </div>
            </div>

            <div style={{ margin: "15px 0" }} className="wrap-information-bank">
              <div className="information-bank">
                <div
                  style={{
                    fontSize: "40px",
                    color: "green",
                    textDecoration: "underline",
                    fontWeight: "600",
                  }}
                >
                  02
                </div>
                <div
                  style={{
                    flex: "1",
                    paddingLeft: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      style={{
                        color: "red",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Chuyển khoản Nhật Bản
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: "green",
                        }}
                      ></div>
                      <div style={{ marginLeft: "10px" }}>
                        <label style={{ width: "90px" }}>Nội dung</label>: Họ và
                        Tên - Số điện thoại
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: "green",
                        }}
                      ></div>
                      <div style={{ marginLeft: "10px" }}>
                        <label style={{ width: "90px" }}>Số tiền</label>
                        {": "}
                        599,000đ.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: "green",
                        }}
                      ></div>
                      <div style={{ marginLeft: "10px" }}>
                        Học full các khóa học tại website.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: !isMobile ? "200px" : "150px",
                    padding: "5px",
                    border: "1px solid green",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    src={image1}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div style={{ margin: "15px 0", display: "flex" }}>
              <div style={{ width: "80%", color: "#000", fontWeight: "600" }}>
                Lưu ý: Sau khi chuyển khoản, vui lòng chụp bill chuyển khoản,
                gửi tới đường link bên cạnh để admin phê duyệt.
              </div>
              <div style={{ width: "20%" }}>
                <Button
                  variant="success"
                  className="login-button"
                  style={{ borderRadius: "20px" }}
                >
                  Nhắn Tin Admin
                </Button>
                <Button
                  variant="success"
                  className="login-button"
                  style={{ marginTop: "5px", borderRadius: "20px" }}
                >
                  Zalo Hỗ Trợ
                </Button>
              </div>
            </div>

            <div style={{ fontWeight: "bold" }}>Liên hệ với admin:</div>
            <div style={{ fontWeight: "600" }}>
              Bạn có thể chủ động nhắn tin qua Facebook Messenger tại:
              Khotiengnhat.com/chat
            </div>
            <div style={{ fontWeight: "600" }}>
              Hoặc bạn cũng có thể liên hệ trực tiếp đến Admin qua Số điện
              thoại: 0989.584.777
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Payment;

import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { SiZalo } from "react-icons/si";
import Accordion from "react-bootstrap/Accordion";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import { GoUnlock } from "react-icons/go";
import { fetchList } from "../../services";
import { useParams } from "react-router-dom";
import image1 from "../../assets/images/play.png";
import { TailSpin } from "react-loader-spinner";
import { RotatingLines } from "react-loader-spinner";
import { isLoginAndPayment, isLogin } from "../../utils";
import { getImage } from "../../utils";
import { useWindowWidth } from "../../context/WindowWidthContext";
import { useGlobalState } from "../../context/GlobalContext";
import { Modal, Button, Form } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { DOMAIN } from "../../constants";
import { useNavigate } from "react-router-dom";

import "./course.css";

const styles = {
  contentMasterCategory: (isMobile) => ({
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
  }),
  contentMasterCategoryLoading: (isMobile) => ({
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  contentContact: (isMobile) => ({
    margin: !isMobile ? "5px 20px 0 20px" : "5px 0px 0 0px",
    backgroundColor: "#fff",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    padding: "10px 30px",
    display: "flex",
    alignItems: "center",
  }),
};

const closeIconStyles = {
  position: "absolute",
  top: "-10px",
  right: "-5px",
  width: "30px",
  height: "30px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  cursor: "pointer",
  color: "#333",
  border: "1px solid #ccc",
  zIndex: "1050",
  transition: "background-color 0.3s ease, color 0.3s ease",
};

const getYouTubeVideoId = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const YouTubeVideo = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);
  const videoId = getYouTubeVideoId(url);
  const handleLoad = () => {
    setIsLoading(false);
  };
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "56.25%",
        height: 0,
        overflow: "hidden",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <RotatingLines
            height="40"
            width="40"
            color="#ddd"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        onLoad={handleLoad}
        allowFullScreen
        title="YouTube Video"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

const MultiOpenAccordion = ({ parts, handleClickItem }) => {
  const { isMobile } = useWindowWidth();
  const [openItems, setOpenItems] = useState([]);

  const handleToggle = (eventKey) => {
    const abc = eventKey.split("_")[2] === "1";
    if (abc) return;
    setOpenItems((prev) =>
      prev.includes(eventKey)
        ? prev.filter((key) => key !== eventKey)
        : [...prev, eventKey]
    );
  };

  return (
    <div className="content-course">
      <div className="title">Chương trình bài học</div>
      <div
        className={isMobile ? "wrap-content-header-mobile detail" : "detail"}
      >
        <Accordion activeKey={openItems} onSelect={handleToggle}>
          {parts.map((item, index) => {
            return (
              <Accordion.Item key={index} eventKey={`${index}_p_0`}>
                <Accordion.Header>
                  <div className="wrap-content-header">
                    <div className="wrap-action">
                      {openItems.includes(`${index}_p_0`) ? (
                        <FaChevronDown
                          style={{
                            marginTop: "3px",
                            minWidth: !isMobile ? "16px" : "13px",
                          }}
                        />
                      ) : (
                        <FaChevronUp
                          style={{
                            marginTop: "3px",
                            minWidth: !isMobile ? "16px" : "13px",
                          }}
                        />
                      )}
                      <div className="title-course">{item.name}</div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion activeKey={openItems} onSelect={handleToggle}>
                    {item.courses.map((course, sub) => {
                      const flag =
                        course.open && !isLoginAndPayment() ? "1" : "0";
                      return (
                        <Accordion.Item
                          eventKey={`${index}_${sub}_${flag}`}
                          item={course}
                          key={sub}
                        >
                          <Accordion.Header
                            onClick={() => handleClickItem(course.open)}
                          >
                            <div className="wrap-content-header">
                              <div className="wrap-action">
                                {openItems.includes(
                                  `${index}_${sub}_${flag}`
                                ) ? (
                                  <FaChevronDown
                                    style={{
                                      marginTop: "3px",
                                      minWidth: !isMobile ? "16px" : "13px",
                                    }}
                                  />
                                ) : (
                                  <FaChevronUp
                                    style={{
                                      marginTop: "3px",
                                      minWidth: !isMobile ? "16px" : "13px",
                                    }}
                                  />
                                )}
                                <div className="title-sub-course">
                                  {`${index + 1}.${sub + 1} `}
                                  {course.name}
                                </div>
                              </div>
                              {flag === "1" ? (
                                <CiLock
                                  style={{
                                    minWidth: !isMobile ? "18px" : "13px",
                                  }}
                                  size={!isMobile ? 18 : 13}
                                />
                              ) : (
                                <GoUnlock
                                  style={{
                                    minWidth: !isMobile ? "18px" : "13px",
                                  }}
                                  color="green"
                                  size={!isMobile ? 18 : 13}
                                />
                              )}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {course.documentUrl &&
                              course.documentUrl !== "" && (
                                <div
                                  style={{
                                    margin: "5px 0px 10px 0px",
                                    fontWeight: "600",
                                  }}
                                >
                                  <strong>Tài Liệu: </strong>
                                  <a target="_blank" href={course.documentUrl}>
                                    {course.documentUrl}
                                  </a>
                                </div>
                              )}

                            {openItems.includes(`${index}_${sub}_${flag}`) &&
                              course.url &&
                              course.url !== "" && (
                                <YouTubeVideo url={course.url} />
                              )}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

const GreatCategory = () => {
  const navigate = useNavigate();
  const { isMobile } = useWindowWidth();
  const { state } = useGlobalState();
  const support = state.supportData;
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupRegister, setShowPopupRegister] = useState(false);

  const popupData = state.popupData.find((x) => x.type === "POPUP_PAYMENT");
  const popupRegister = state.popupData.find(
    (x) => x.type === "POPUP_REGISTER"
  );

  const { id } = useParams();
  const [masterCategory, setMasterCategory] = useState({
    categories: [],
  });
  const [count, setCount] = useState();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const rootDatas = await fetchList(
        `/public/categories/search-part?id=${id}`
      );
      if (rootDatas && rootDatas.length > 0) {
        setMasterCategory(rootDatas[0]);
        let countData = 0;
        rootDatas[0].parts.forEach((element) => {
          countData = countData + element.courses.length;
        });
        setCount(countData);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const rootDatas = await fetchList(`/public/teachers`);
      let allGvs = [];
      (masterCategory.parts || []).forEach((element) => {
        element.courses.forEach((x) => {
          const gv = rootDatas.find((c) => c.id === x.teacherId);
          if (gv && !allGvs.includes(gv)) {
            allGvs.push(gv.name);
          }
        });
      });
      setTeachers(allGvs);
    };
    fetchData();
  }, [masterCategory]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClosePopupRegister = () => {
    setShowPopupRegister(false);
  };

  const handleClickItem = (isLock) => {
    if (!isLock) {
      return;
    }

    if (!isLogin()) {
      setShowPopupRegister(true);
      return;
    }
    if (!isLoginAndPayment()) {
      setShowPopup(true);
      return;
    }
  };

  return (
    <Container
      className={isMobile ? "mobile-container main-content" : "main-content"}
      style={{
        paddingBottom: "10px",
        flexGrow: 1,
        minHeight: "calc(100vh - 60px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <div style={styles.contentMasterCategoryLoading(isMobile)}>
          <TailSpin color="green" radius={"8px"} />
        </div>
      ) : (
        <div style={styles.contentMasterCategory(isMobile)}>
          <div
            style={{
              padding: !isMobile ? "20px" : "4px",
              borderRadius: "10px",
              boxShadow:
                " 2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div className="d-flex" style={{ flexDirection: "row" }}>
              <div
                style={{
                  width: !isMobile ? "150px" : "100px",
                  minWidth: isMobile ? "110px" : "unset",
                }}
              >
                <img
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                  src={getImage(masterCategory.image)}
                  alt=""
                />
              </div>
              <div
                className="d-flex"
                style={{
                  flexDirection: "column",
                  paddingLeft: !isMobile ? "10px" : "3px",
                }}
              >
                <div className="d-flex">
                  <div
                    style={{
                      width: "10px",
                      backgroundColor: "green",
                      border: "1px solid #fff",
                      borderRadius: "5px",
                      height: "36px",
                      marginRight: !isMobile ? "10px" : "5px",
                      marginLeft: !isMobile ? "10px" : "5px",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: !isMobile ? "24px" : "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {masterCategory.name}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: !isMobile ? "16px" : "14px",
                    marginTop: !isMobile ? "0px" : "-7px",
                    marginLeft: !isMobile ? "30px" : "20px",
                    color: "#000",
                    fontWeight: 500,
                  }}
                >
                  Giáo Viên:{" "}
                  <span style={{ color: "blue" }}>{teachers.join(", ")}</span>
                </div>
                <div
                  style={{
                    fontSize: !isMobile ? "16px" : "14px",
                    marginLeft: !isMobile ? "30px" : "20px",
                    marginTop: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: !isMobile ? "16px" : "13px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                      src={image1}
                      alt=""
                    />
                  </div>
                  <div style={{ marginLeft: "5px" }}>
                    {`${count} `}
                    <span style={{ color: "blue", fontWeight: 500 }}>
                      Videos
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: !isMobile ? "20px 0" : "10px 0" }}>
            <MultiOpenAccordion
              parts={masterCategory.parts || []}
              handleClickItem={handleClickItem}
            />
          </div>
        </div>
      )}

      <div style={styles.contentContact(isMobile)}>
        <SiZalo
          style={{ marginRight: "10px", color: "blue", fontSize: "30px" }}
        />
        <div style={{ fontWeight: "bold" }}>
          {support.name && support.phone
            ? `${support.name}: ${support.phone}`
            : "Ms. Yến: 0978365115"}
        </div>
      </div>

      {showPopup && popupData && (
        <Modal show={showPopup} onHide={handleClosePopup}>
          <Modal.Body
            style={{
              position: "relative",
              padding: 0,
            }}
          >
            <span style={closeIconStyles} onClick={handleClosePopup}>
              <FaTimes />
            </span>
            <img
              style={{ height: "auto", objectFit: "cover" }}
              className="d-block w-100"
              src={`${DOMAIN}${popupData.image}`}
              alt=""
            />
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#58d062" }}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#fff",
                  width: "80%",
                  color: "#000",
                  padding: "10px",
                  borderRadius: "10px",
                  textTransform: "uppercase",
                  fontWeight: "600",
                  borderColor: "#ddd",
                }}
                onClick={() => {
                  setShowPopup(false);
                  navigate("/payment");
                }}
              >
                Thanh Toán Ngay
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {showPopupRegister && popupRegister && (
        <Modal show={showPopupRegister} onHide={handleClosePopupRegister}>
          <Modal.Body
            style={{
              position: "relative",
              padding: 0,
            }}
          >
            <span style={closeIconStyles} onClick={handleClosePopupRegister}>
              <FaTimes />
            </span>
            <img
              style={{ height: "auto", objectFit: "cover" }}
              className="d-block w-100"
              src={`${DOMAIN}${popupRegister.image}`}
              alt=""
            />
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#58d062" }}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#fff",
                  width: "80%",
                  color: "#000",
                  padding: "10px",
                  borderRadius: "10px",
                  textTransform: "uppercase",
                  fontWeight: "600",
                  borderColor: "#ddd",
                }}
                onClick={() => {
                  setShowPopup(false);
                  navigate("/sign-up");
                }}
              >
                Đăng Ký Ngay
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default GreatCategory;
